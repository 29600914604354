/*
 * `gatsby-background-image` is a React component which for background-images
 * provides, what Gatsby’s own `gatsby-image` does for the rest of your images
 * and even more:
 *
 * For more information, see the docs:
 * - `gatsby-background-image`: https://www.gatsbyjs.org/packages/gatsby-background-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import classNames from 'classnames';

import bgImageTypes from '../../types/bgImageTypes';

import './BgImage.scss';

class BgImage extends React.PureComponent {
  static propTypes = bgImageTypes;

  render() {
    const {
      backgroundColor,
      className,
      tag,
      data,
      url,
      critical,
      ...props
    } = this.props;
    const image = data.images.edges.find((n) => n.node.relativePath.includes(url));
    if (!image) {
      return null;
    }

    const imageData = image.node.childImageSharp.fluid;
    return (
      <BackgroundImage
        backgroundColor={backgroundColor}
        className={classNames('BgImage', className)}
        Tag={tag}
        fluid={imageData}
        critical={critical}
        {...props}
      />
    );
  }
}

const BgImageStaticQuery = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {ext: {nin: [".svg", "", ".js"]}}) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 2048) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <BgImage data={data} {...props} />}
  />
);

export default BgImageStaticQuery;
