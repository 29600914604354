import PropTypes from 'prop-types';

import dataTypes from './dataTypes';

export const bgImageDefaultProps = {
  backgroundColor: 'var(--grey)',
  className: null,
  tag: 'div',
  critical: false,
};

const bgImageTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  tag: PropTypes.string,
  data: PropTypes.shape({
    images: dataTypes.images,
  }),
  url: PropTypes.string.isRequired,
  critical: PropTypes.bool,
};

export default bgImageTypes;